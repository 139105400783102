svg g g {
  cursor: pointer
}

body {
  margin: 0;
  letter-spacing: 0.3px !important;
}
svg > g > g.google-visualization-tooltip {
  pointer-events: none
}
